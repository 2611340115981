/** @jsx jsx */
import { jsx, Heading, Box, Flex, Text } from 'theme-ui';
// @ts-ignore
import kebabCase from 'lodash.kebabcase';
import { Link } from 'gatsby';
import Layout from './layout';
import useMinimalBlogConfig from '../hooks/use-minimal-blog-config';
import Seo from './seo';
import replaceSlashes from '../utils/replaceSlashes';

type PostsProps = {
  list: {
    fieldValue: string;
    totalCount: number;
  }[];
};

const Tags = ({ list }: PostsProps) => {
  const { tagsPath, basePath } = useMinimalBlogConfig();

  return (
    <Layout>
      <Seo title="Tags" />
      <Heading as="h1" variant="styles.h2">
        Tags
      </Heading>
      <Box mt={[4, 5]}>
        {list.map(listItem => (
          <Flex
            key={listItem.fieldValue}
            mb={[1, 1, 2]}
            sx={{ alignItems: `center` }}
          >
            <Link
              sx={t => ({ ...t.styles?.a, variant: `links.listItem`, mr: 2 })}
              to={replaceSlashes(
                `/${basePath}/${tagsPath}/${kebabCase(listItem.fieldValue)}`
              )}
            >
              {listItem.fieldValue}{' '}
              <Text sx={{ color: `secondary` }}>({listItem.totalCount})</Text>
            </Link>
          </Flex>
        ))}
      </Box>
    </Layout>
  );
};

export default Tags;
